import { render, staticRenderFns } from "./CardLife.vue?vue&type=template&id=a2cd6644&scoped=true&"
import script from "./CardLife.vue?vue&type=script&lang=js&"
export * from "./CardLife.vue?vue&type=script&lang=js&"
import style0 from "./CardLife.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CardLife.vue?vue&type=style&index=1&id=a2cd6644&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2cd6644",
  null
  
)

export default component.exports