var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-wrap idon"},[_c('div',{staticClass:"swiper-All-wrapper"},[_c('swiper',{ref:"mySwiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption},on:{"slide-change-transition-start":_vm.slideStart,"slide-change-transition-end":_vm.slideEnd}},_vm._l((_vm.swiperList),function(card,index){return _c('swiper-slide',{key:index + 'A'},[_c('div',{staticClass:"swiper-item"},[_c('h3',{staticClass:"s-title",domProps:{"textContent":_vm._s(card.text)}}),_c('div',{staticClass:"tv-box",class:{
              crayon1: card.color === '#b0cc43',
              crayon2: card.color === '#e6e618',
              crayon3: card.color === '#e68629',
              crayon4: card.color === '#e5602e',
              crayon5: card.color === '#cd2045',
              crayon6: card.color === '#b41f73',
              crayon7: card.color === '#962d91',
              crayon8: card.color === '#592d8c',
              crayon9: card.color === '#303089',
              crayon10: card.color === '#233f93',
              crayon11: card.color === '#0a57a4',
              crayon12: card.color === '#1474aa',
              crayon13: card.color === '#1691b2',
              crayon14: card.color === '#15a85c',
              crayon15: card.color === '#7eb442'
            }},[_c('div',{staticClass:"img-wrap"},[_c('img',{staticClass:"swiper-lazy",attrs:{"src":'https://www.swallow.pro' + card.images}})])])])])}),1),_c('div',{staticClass:"swiper-btn prev",class:{ isHide: this.currentIndex === 0 },on:{"click":function($event){return _vm.prev()}}},[_c('img',{attrs:{"src":require("@assets/images/util/icon_swiper_mini_before.svg")}})]),_c('div',{staticClass:"swiper-btn next",class:{ isHide: this.currentIndex === this.swiperList.length - 1 },on:{"click":function($event){return _vm.next()}}},[_c('img',{attrs:{"src":require("@assets/images/util/icon_swiper_mini_next.svg")}})])],1)])}]

export { render, staticRenderFns }