<template>
  <div class="info-area">

    <div class="area type1" v-if="cardType === 1">
      <div class="p-wrapper-flag" v-if="isFinalFlag === true || isFinalFlag === 'true'">
        <p>우리는 그동안 보석같은 우리 삶의 흔적들을 함께 돌이켜 봤습니다.</p>
      </div>
      <swiper-life-card :swiperList="swiperList" v-if="view.isSwiperPrepare"></swiper-life-card>
    </div>

    <div class="area" v-if="cardType === 2">
      <div class="h3-wrapper">
        <h3
          class="h3-title"
          v-text="'[' + this.cardInfo.userName + ']님의 멋진 작품이 완성 되었습니다!'"
          v-if="isFinalFlag !== 'true' && isFinalFlag !== true"
        />
        <h3 class="h3-title flag" v-else>
          <p v-if="this.cardInfo.day === 7">
            마음을 담아 만다라 그림에 빛깔을 입혀보기도 했습니다.
          </p>
          <p v-else-if="this.cardInfo.day === 8">
            우리 자신에게 상을 주며, 내 삶을 격려하는 기회도 가졌습니다.
          </p>
          <p v-else-if="this.cardInfo.day === 9">
            나에게 편지를 보내면서 그 동안 내가 얼마나 열심히 살아왔는지
            <br>
            다시 깨달을 수 있었습니다.
          </p>
        </h3>
      </div>
      <div class="img-wrapper">
        <img class="share-img" :src="'https://www.swallow.pro' + this.cardInfo.images" />
      </div>
    </div>

    <div class="area" v-if="cardType === 3">
      <div class="h3-wrapper">
        <h3
          class="h3-title"
          v-text="'[' + this.cardInfo.userName + ']님의 멋진 작품이 완성 되었습니다!'"
        />
      </div>

      <slick
        id="carousel"
        ref="slick"
        :options="slickOptions"
        @afterChange="handleAfterChange"
        @beforeChange="handleBeforeChange"
        @breakpoint="handleBreakpoint"
        @destroy="handleDestroy"
        @edge="handleEdge"
        @init="handleInit"
        @reInit="handleReInit"
        @setPosition="handleSetPosition"
        @swipe="handleSwipe"
        @lazyLoaded="handleLazyLoaded"
        @lazyLoadError="handleLazeLoadError"
      >
        <a :href="'https://www.swallow.pro' + this.cardInfo.images[0]"
          ><img class="img-a" :src="'https://www.swallow.pro' + this.cardInfo.images[0]" alt=""
        /></a>
        <a :href="'https://www.swallow.pro' + this.cardInfo.images[1]"
          ><img class="img-b" :src="'https://www.swallow.pro' + this.cardInfo.images[1]" alt=""
        /></a>
        <a :href="'https://www.swallow.pro' + this.cardInfo.images[2]"
          ><img class="img-c" :src="'https://www.swallow.pro' + this.cardInfo.images[2]" alt=""
        /></a>
      </slick>
    </div>

    <div class="area" v-if="cardType === 4">
      <div class="h3-wrapper" v-if="isFinalFlag === 'true' || isFinalFlag === true">
        <h3 class="h3-title flag b" v-if="this.cardInfo.day === 11">
          각자의 삶에서 주인인 우리는,
          <br>
          같은 고민을 안고 살아가는 이들에게도 마음을 담아 편지를 전했습니다.
        </h3>
        <h3 class="h3-title flag c" v-if="this.cardInfo.day === 12">
          가족으로 인한 삶의 무게에 대해서도 고민했습니다.
          <br>
          그러나 가족으로부터 얻는 보람과 기쁨은 놓칠 수 없는 보물이었습니다.
        </h3>
        <h3 class="h3-title flag d" v-if="this.cardInfo.day >= 13 && this.cardInfo.day <= 20">
          힘들어하는 이들에게 나의 목소리를 담아 보냈던 편지들을
          <br>
          다시 떠올리며 음미해보겠습니다.
        </h3>
      </div>
      <div class="img-wrapper type4" v-if="this.cardInfo.day !== 36">
        <div class="card-img">
          <img :src="'https://www.swallow.pro' + this.cardInfo.images[0]" />
        </div>
      </div>
      <div class="img-wrapper type4 else" v-else>
        <div class="p-wrapper" v-if="isFinalFlag === 'true' || isFinalFlag === true">
          <p>여전히 마음속에서 빛나고 있는</p>
          <p>다른 사람들로부터 받았던 고마움과,</p>
        </div>
        <div class="card-img">
          <img :src="'https://www.swallow.pro' + this.cardInfo.images[0]" />
        </div>
      </div>
    </div>

    <div class="area" :class="{area5type : cardType === 5}" v-if="cardType === 5">
      <div class="img-wrapper type5">
        <div class="card-img" v-if="this.cardInfo.day === 21">
          <img src="@assets/images/share/twentyone.png" />
          <div class="p-wrapper">
            <div class="p-a">
              <p>우리는 삶이 던지는 많은 문제들과 더불어 살아감으로써</p>
              <p>진정한 삶의 의미를 깨달을 수 있습니다.</p>
            </div>
            <div class="p-b">
            <p>삶 속에서 부딪히는 문제들을 피하려 하지 않고</p>
            <p>성실히 답하여 살아갈 때에</p>
            </div>
            <div class="p-c">
              <p>우리는 행복의 열쇠를 발견할 수 있을 것 입니다.</p>
            </div>
          </div>
        </div>
        <div class="card-img type5-b" v-else-if="this.cardInfo.day === 25">
          <div class="p-wrapper-flag" v-if="isFinalFlag === true || isFinalFlag === 'true'">
            <p>그 동안의 활동들을 우리는 앞으로도 계속해 나갈 수 있습니다.</p>
          </div>
          <img src="@assets/images/share/myeonsang.png"  v-if="isFinalFlag !== true && isFinalFlag !== 'true'"/>
          <img src="@assets/images/share/day39_25_myeonsang.png" v-else />
           <div class="p-wrapper" v-if="isFinalFlag !== true && isFinalFlag !== 'true'">
            <div class="p-a">
              <p>우리는 작은 삶의 문제에도 온 마음을 빼앗기곤 합니다.</p>
              <p>그래서 일상에서 벌어지는 다양한 일들을 보지 못한 채</p>
              <p>지나쳐버릴 때가 있습니다.</p>
            </div>
            <div class="p-b">
            <p>시련과 부정적 감정들이 삶을 지배할 때면</p>
            <p지금 이 순간에 집중하십시오.</p>
            </div>
            <div class="p-c">
              <p>현재의 나로 돌아올 수 있습니다.</p>
            </div>
          </div>
        </div>
        <div class="card-img type5-special" v-else-if="this.cardInfo.day === 28">
          <div class="p-title" v-if="isFinalFlag !== true && isFinalFlag !== 'true'">
            <p>우리가 삶의 마지막 순간을 고민하는 것은 좋은 인생을 살기 위함입니다.</p>
            <p>나의 묘비명이 보여주고 있는 내 삶의 의미, 그리고 중요한 가치들을</p>
            <p>노년이라는 소중한 시간을 통해 실현해 나가시길 바랍니다.</p>
          </div>
          <div class="p-title" v-else>
            <p>우리는 인생의 소중한 가치에 대해 끊임없이 고민하고 있으며</p>
          </div>
          <img src="@assets/images/share/day28_background.png" />
            <div class="p-wrapper flag" :class="{flagAdd : isFinalFlag === true || isFinalFlag === 'true'}">
              <div class="p-a">
                <p> {{ this.cardInfo.text[0] }}</p>
              </div>
            </div>
        </div>
        <div class="card-img type5-c" v-else-if="this.cardInfo.day === 29">
          <div class="p-wrapper-flag" v-if="isFinalFlag === true || isFinalFlag === 'true'">
            <p>내 인생의 목적지를 향해 천천히, 그러나 꾸준히 다가가고 있습니다.</p>
            <p>인생이라는 버스는 멈추지 않아야 종점에 도달할 수 있습니다.</p>
          </div>
          <img src="@assets/images/share/bus_clear.png" v-if="isFinalFlag === true || isFinalFlag === 'true'" />
          <img src="@assets/images/share/bus.png" v-else/>
          <div class="p-wrapper" v-if="isFinalFlag !== true && isFinalFlag !== 'true'">
            <div class="p-a">
              <p>우리는 모두 '인생'이라는 버스의 주인공입니다.</p>
              <p>버스가 정류장에 설 때마다 여러 손님들이 타고 내릴 것이고</p>
              <p>위협적인 손님도 탈 것입니다.</p>
            </div>
            <div class="p-b">
            <p>만약 무서운 손님 때문에 버스가 출발하지 못하면,</p>
            <p>버스는 목적지에 도착할 수 없습니다.</p>
            <p>우리의 인생버스는 종점까지 달려야 합니다.</p>
            </div>
            <div class="p-c">
              <p>그 운전대는 당신의 손에 있습니다.</p>
            </div>
          </div>
        </div>
        <div class="card-img type5-d" v-else-if="this.cardInfo.day === 35">
           <img src="@assets/images/share/35day.png" v-if="isFinalFlag !== true && isFinalFlag !== 'true'"/>
           <div class="p-wrapper" v-if="isFinalFlag !== true && isFinalFlag !== 'true'">
             <div class="p-a">
               <p>다른 이들의 작은 친절이 나에게 고마운 기억으로 남아있듯,</p>
               <p>나의 작은 배려가 누군가에게는 큰 도움이 되었을지도 모릅니다.</p>
             </div>
             <div class="p-b">
               <p>우리가 나누었던 배려와 사랑은 오래도록 남아서</p>
               <p>내 삶에 의미와 가치를 더해줍니다.</p>
             </div>
             <div class="p-c">
               <p>이것이 '나눔'과 '배려'의 힘이겠지요.</p>
             </div>
           </div>
           <div class="p-wrapper-flag" v-else>
             <p>내가 '너를 위해' 시작했지만 결국 '나를 행복하게' 해주었던 일.</p>
             <p>이 모든 것을 모아 '나눔과 배려'를 만들어 나가고 있습니다.</p>
           </div>
           <div class="img-wrapper-flag" v-if="isFinalFlag === true || isFinalFlag === 'true'">
             <div class="img-top">
               <img src="@assets/images/share/35day_img1.png" />
               <img src="@assets/images/share/35day_img2.png" />
             </div>
             <div class="img-bottom">
               <img src="@assets/images/share/35day_img3.png" />
               <img src="@assets/images/share/35day_img4.png" />
             </div>
           </div>
        </div>
      </div>
    </div>

    <div class="area" v-if="cardType === 6">
      <div class="img-wrapper type6 a" v-if="this.cardInfo.day === 22">
        <div class="img-wrapper-left">
          <p class="img-title left">내가 현재 경험하고 있는<br> 고통스럽고 힘든 문제들</p>
          <img src="@assets/images/share/smile_editback1.png" />
          <div class="img-content left">
            <div class="p-wrapper" v-for="(item, index) in swiperList.slice(0,5)" :key="index">
              <p>
                {{item.text}}
              </p>
            </div>
          </div>
        </div>
        <div class="img-wrapper-right">
          <p class="img-title right">이것이 내게 크게 문제되지<br> 않는다면 하고 싶은 일들</p>
          <img src="@assets/images/share/smile_editback2.png" />
          <div class="img-content right">
            <div class="p-wrapper" v-for="(item, index) in swiperList.slice(5,10)" :key="index">
              <p>
                {{item.text}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="img-wrapper type6 c" v-else :class="{b : isFinalFlag === true || isFinalFlag === 'true'}">
        <div class="img-wrapper-left" v-if="isFinalFlag !== true && isFinalFlag !== 'true'">
          <p class="img-title left">지금 나를 가장 괴롭히는<br> 생각이나 걱정은?</p>
          <img src="@assets/images/share/smile_editback1.png" />
          <div class="img-content left">
            <div class="p-wrapper" v-for="(item, index) in swiperList.slice(0,4)" :key="index">
              <p>
                {{item.text}}
              </p>
            </div>
          </div>
        </div>
        <div class="p-wrapper" v-else>
          <p>삶에서 소중한 것들을 놓치지 않고 행복하고 즐겁게 살아갈 수 있으며,</p>
        </div>
        <div class="img-wrapper-right flag">
          <p class="img-title right flag">그 걱정에 매달리느라<br> 현재 놓치고 있는것은?</p>
          <img src="@assets/images/share/smile_editback2.png" />
          <div class="img-content right flag">
            <div class="p-wrapper" v-for="(item, index) in swiperList.slice(4,8)" :key="index">
              <p>
                {{item.text}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="area" v-if="cardType === 7">
      <div class="img-wrapper type7" v-if="this.cardInfo.day === 23">
        <div class="content-title" v-if="isFinalFlag !== true && isFinalFlag !== 'true'">
          <p class="p-a">내 삶이 너무나 고단하다면, 한 발짝 뒤로 물러나 보십시오.</p>
          <p class="p-b">힘겨움 때문에 보이지 않았던 일상의 행복과 기쁨이</p>
          <p class="p-c">눈에 들어오기 시작할 것입니다.</p>
        </div>
        <div class="content-title" v-else>
          <p class="p-a">이제 우리는 눈 앞의 상자 너머에 있는</p>
          <p class="p-b">풍경을 편안히 바라볼 수 있으며</p>
        </div>
        <div class="content-img">
          <img :src="'https://www.swallow.pro' + this.cardInfo.images[0]" />
          <img src="@assets/images/share/box.png" />
        </div>
      </div>
      <div class="img-wrapper type7 b" v-else>
        <div class="content-title">
          <p class="p-a">우리가 삶의 마지막 순간을 고민하는 것은 좋은 인생을 살기 위함입니다.</p>
          <p class="p-b">나의 묘비명이 보여주고 있는 내 삶의 의미, 그리고 중요한 가치들을</p>
          <p class="p-c">노년이라는 소중한 시간을 통해 실현해 나가시길 바랍니다.</p>
        </div>
        <div class="content-img">
          <img :src="'https://www.swallow.pro' + this.cardInfo.images[0]" />
        </div>
      </div>
    </div>

    <div class="area" v-if="cardType === 8">
      <div class="img-wrapper type8" v-if="this.cardInfo.day === 24">
        <div class="p-wrapper" v-if="isFinalFlag === 'true' || isFinalFlag === true">
          <p>흘러가는 낙엽도 여유있게 지켜볼 수 있습니다.</p>
        </div>
        <img src="@assets/images/share/smile_24_gif.gif" />
      </div>
      <div class="img-wrapper type8 b" v-else-if="this.cardInfo.day === 27">
        <div class="p-wrapper" v-if="isFinalFlag !== 'true' && isFinalFlag !== true">
          <p>우리는 그 모든 괴로움을 디딤돌 삼아</p>
          <p>진정으로 원하는 삶을 향해</p>
          <p>나아갈 수 있을 것 입니다.</p>
        </div>
        <div class="p-wrapper-flag" v-else>
          <p>삶의 시련을 이겨내고 당당히 앞으로 걸어 나갈 수 있습니다.</p>
        </div>
        <img src="@assets/images/share/smile_27_gif.gif" />
      </div>
      <div class="img-wrapper type8 c" v-else-if="this.cardInfo.day === 30">
          <div class="p-wrapper-flag" v-if="isFinalFlag === true || isFinalFlag === 'true'">
            <p>나의 삶은 소중한 가치로 채워져 있으며,</p>
          </div>
          <img :src="'https://www.swallow.pro' + this.cardInfo.images[0]" />
      </div>
    </div>

    <div class="area type9List" v-if="cardType === 9">
      <div class="p-wrapper" v-if="isFinalFlag === true || isFinalFlag === 'true'">
        <p>우리는 생각했던 실천계획들을 하나하나 해나가고 있습니다.</p>
      </div>
      <div class="img-wrapper type9">
        <div class="content-title">
          <p>가치가 이끄는 삶을 위한 실천 목록</p>
        </div>
        <div class="content-body"  v-for="(item, index) in titleList" :key="index" :class="item.selectFlag ? 'active' : ''">
          <div class="body-top">
            <p >
              {{item.titleTop}}
            </p>
          </div>
          <div class="body-bottom">
            <p>
              {{item.titleBottom}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="area type10List" v-if="cardType === 10">
      <div class="img-wrapper type10" v-if="this.cardInfo.day === 32">
        <div class="p-wrapper" v-if="isFinalFlag === true || isFinalFlag === 'true'">
          <p>우리의 활동은 이제부터 시작입니다!</p>
        </div>
        <div class="content top">
          <div class="content-title a">
            <img src="@assets/images/share/mark.png" />
            <p>내가 가보고 싶은 곳, 하고 싶은 활동</p>
          </div>
          <div class="content-body a">
            <p v-text="this.cardInfo.text[0]"></p>
          </div>
        </div>
        <div class="content bottom">
          <div class="content-title b">
            <img src="@assets/images/share/mark.png" />
            <p>활기찬 생활을 위한 나만의 노하우</p>
          </div>
          <div class="content-body b">
            <p v-text="this.cardInfo.text[1]"></p>
          </div>
        </div>
      </div>
      <div class="img-wrapper type10" v-else-if="this.cardInfo.day === 34">
        <div class="p-wrapper" v-if="isFinalFlag === true || isFinalFlag === 'true'">
          <p>우리는 매일의 생활에서 새로운 느낌들을 끊임없이 담아갈 것입니다.</p>
        </div>
        <div class="content top">
          <div class="content-title a">
            <img src="@assets/images/share/mark.png" />
            <p>소감</p>
          </div>
          <div class="content-body a">
            <p v-text="this.cardInfo.text[0]"></p>
          </div>
        </div>
      </div>
      <div class="img-wrapper type10" v-else-if="this.cardInfo.day === 38">
        <div class="p-wrapper" v-if="isFinalFlag === true || isFinalFlag === 'true'">
          <p>우리들은 지금까지 그러했듯이 내 삶을 소중히 여기고,</p>
          <p>인생의 가치를 찾으며, 나눔과 베품을 실천하는</p>
          <p>소중한 여정을 계속해 나갈 것 입니다.</p>
        </div>
        <div class="content fir">
          <div class="content-title a">
            <img src="@assets/images/share/mark.png" />
            <p>어떤 나눔을 실천하셨나요?</p>
          </div>
          <div class="content-body a">
            <p v-text="this.cardInfo.text[0]"></p>
          </div>
        </div>
        <div class="content sec">
          <div class="content-title b">
            <img src="@assets/images/share/mark.png" />
            <p>나눔을 실천해보신 소감은 어떠신가요?</p>
          </div>
          <div class="content-body b">
            <p v-text="this.cardInfo.text[1]"></p>
          </div>
        </div>
        <div class="content third">
          <div class="content-title c">
            <img src="@assets/images/share/mark.png" />
            <p>내가 생각하는 나눔이란 무엇입니까?</p>
          </div>
          <div class="content-body c">
            <p v-text="this.cardInfo.text[2]"></p>
          </div>
        </div>
        <div class="content fourth">
          <div class="content-title d">
            <img src="@assets/images/share/mark.png" />
            <p>앞으로 꼭 실천해보고 싶은 나눔이 있다면 어떤 것인가요?</p>
          </div>
          <div class="content-body d">
            <p v-text="this.cardInfo.text[3]"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="area type11List" v-if="cardType === 11">
      <div class="img-wrapper type11">
        <div class="p-wrapper" v-if="isFinalFlag === true || isFinalFlag === 'true'">
          <p>앞으로도 베풀고 나누는 삶이 되길 기대합니다.</p>
        </div>
        <div class="content-title">
          <p>나의 나눔 목록</p>
        </div>
        <div class="content-body">
          <div class="body-list" v-for="(item, index) in cardInfo.text" :key="index">
            <p>{{item}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperLifeCard from '@/components/SwiperLifeCard.vue';
import Slick from 'vue-slick';

export default {
  props: {
    cardType: {
      type: Number,
      default: 0,
    },
    cardInfo: {
      type: Object,
    },
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        // arrows: true,
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
      },
      swiperList: [],
      titleList: [],
      titleSelectList: [],
      view: {
        isSwiperPrepare: false,
      },
      isFinalFlag: false,
    };
  },
  created() {
    console.log('준비물 점검 flag2 :', this.$route.query.isFlag);
    this.carryData();
    this.titleData();
    this.isFinalFlag = this.$route.query.isFlag;
    console.log(this.isFinalFlag, ' flag ');
  },
  mounted() {
    if (this.cardInfo.day === 7) {
      document.querySelector('.share-img').style.margin = '5% 0 0 7.5%';
    }
  },
  methods: {
    handleAfterChange(event, slick, currentSlide) {
      console.log('handleAfterChange', event, slick, currentSlide);
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
    },
    handleBreakpoint(event, slick, breakpoint) {
      console.log('handleBreakpoint', event, slick, breakpoint);
    },
    handleDestroy(event, slick) {
      console.log('handleDestroy', event, slick);
    },
    handleEdge(event, slick, direction) {
      console.log('handleEdge', event, slick, direction);
    },
    handleInit(event, slick) {
      console.log('handleInit', event, slick);
    },
    handleReInit(event, slick) {
      console.log('handleReInit', event, slick);
    },
    handleSetPosition(event, slick) {
      console.log('handleSetPosition', event, slick);
    },
    handleSwipe(event, slick, direction) {
      console.log('handleSwipe', event, slick, direction);
    },
    handleLazyLoaded(event, slick, image, imageSource) {
      console.log('handleLazyLoaded', event, slick, image, imageSource);
    },
    handleLazeLoadError(event, slick, image, imageSource) {
      console.log('handleLazeLoadError', event, slick, image, imageSource);
    },

    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    titleData() {
      const titleArrTop = [];
      const titleArrBottom = [];
      const titleArr = [];
      const titleSelectTop = [];
      const titleSelectArr = [];
      let titleObj;
      let titleSelectObj;

      const txt = this.cardInfo.text;
      const select = this.cardInfo.selected;
      console.log(select, 'select');
      console.log(txt, 'txt');
      for (let i = 0; i < txt.length / 2; i++) {
        titleArrTop.push(txt[i]);
        titleSelectTop.push(select[i]);
      }
      for (let j = 6; j < txt.length; j++) {
        titleArrBottom.push(txt[j]);
      }
      for (let k = 0; k < titleArrTop.length; k++) {
        titleObj = {
          titleTop: titleArrTop[k],
          titleBottom: titleArrBottom[k],
          selectFlag: false,
        };
        titleArr.push(titleObj);
      }
      for (let k = 0; k < txt.length / 2; k++) {
        titleSelectObj = {
          titleSelectTop: titleSelectTop[k],
        };
        titleSelectArr.push(titleSelectObj);
      }
      console.log(titleArr, 'titleArr');
      console.log(titleSelectArr, 'titleSelectArr');
      this.titleList = titleArr;
      const newTitleSelect = [];
      for (let z = 0; z < titleSelectArr.length; z++) {
        if (titleArr[titleSelectArr[z].titleSelectTop] === undefined) {
          continue;
        }
        newTitleSelect.push(titleArr[titleSelectArr[z].titleSelectTop]);
      }
      console.log(newTitleSelect, 'newTitleSelect');
      this.titleSelectList = newTitleSelect;
      console.log(this.titleSelectList, 'titleSelectList');
      for (let i = 0; i < titleArr.length; i++) {
        for (let j = 0; j < newTitleSelect.length; j++) {
          if (titleArr[i].titleTop === newTitleSelect[j].titleTop && titleArr[i].titleBottom === newTitleSelect[j].titleBottom) {
            titleArr[i].selectFlag = true;
          }
        }
      }
    },

    carryData() {
      const sampleArr = [];
      let sampleObj;

      const txt = this.cardInfo.text;
      const imgArr = this.cardInfo.images;
      if (imgArr.length >= txt.length) {
        for (let i = 0; i < imgArr.length; i++) {
          sampleObj = {
            images: imgArr[i],
            text: i % 2 !== 0 ? this.cardInfo.text[0] : this.cardInfo.text[1],
            color: this.cardInfo.color[0],
          };
          sampleArr.push(sampleObj);
        }
      } else {
        for (let i = 0; i < txt.length; i++) {
          sampleObj = {
            images: imgArr[0],
            text: this.cardInfo.text[i],
            color: this.cardInfo.color[0],
          };
          sampleArr.push(sampleObj);
        }
      }
      console.log('sampleArr :', sampleArr);
      this.swiperList = sampleArr;
      this.view.isSwiperPrepare = true;
    },
  },
  computed: {},
  components: {
    SwiperLifeCard,
    Slick,
    // VueSlickCarousel,
  },
};
</script>

<style>

/* cardtype4의 css 부분 시작 */
.slick-prev,
.slick-next {
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);
  font-size: 0;
  color: #fff;
  border: 0;
  z-index: 1;
  cursor: pointer;
  background-size: cover;
  background-color: transparent;
}

.slick-dots {
  display: flex;
  position: absolute;
  left: 51.5%;
  bottom: 2%;
  transform: translate(-50%, -50%);
  opacity: 70%;
}

.slick-dots li button {
  background-color: #ccc;
  color: #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none;
}

.slick-dots li.slick-active button {
  color: #8c8c8c;
  background-color: #8c8c8c;
}

#carousel .slick-dots li {
  margin-right: 15px;
}

@media screen and (max-width: 768px) {
  #carousel .slick-dots {
    left: 52.5%;
  }

  #carousel .slick-prev {
    left: 6%;
  }

  #carousel .slick-next {
    right: 6%;
  }

  #carousel .slick-dots {
    bottom: 5%;
  }
}

@media screen and (max-width: 480px) {
  #carousel .slick-dots {
    left: 54.5%;
  }

  #carousel .slick-list img.img-a {
    margin: 40% 0 0 30%;
  }

  #carousel .slick-list img {
    margin: 25% 0 0 25%;
  }

  #carousel .slick-list img {
    width: 50%;
  }

  #carousel .slick-prev {
    left: -2%;
  }

  #carousel .slick-next {
    right: -2%;
  }

  #carousel .slick-dots {
    bottom: 20%;
  }
}

.slick-prev {
  left: 10%;
  width: 64px;
  height: 64px;
  background-image: url("~@/assets/images/icon_before.svg");
}

.slick-prev:after {
  content: "";
}

.slick-next {
  right: 10%;
  width: 64px;
  height: 64px;
  background-image: url("~@/assets/images/icon_next.svg");
}

.slick-next:after {
  content: "";
}

#carousel .slick-list {
  border-radius: 12px;
  overflow: hidden;
  width: 500px;
  height: auto;
  margin: 0 auto;
}
.slick-list img {
  width: 100%;
  height: auto;
}
.slick-list img.img-a {
  margin: 30% 0 0 10%;
}

#carousel .slick-slide {
  float: left;
  height: auto;
  min-height: 1px;
}
/* cardtype4의 css 부분 끝 (carousel 부분은 style scoped가 아닌 다른곳에 설정해야한다.) */
</style>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .app-context.share {
  }
}
@media screen and (max-width: 1023px) {
  .app-context.share {
    .info-area {
    }
  }
}
@media screen and (max-width: 768px) {
  .app-context.share {
    .wrap-context {
      .info-area {
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 625px) {
  .app-context.share .info-area {
    padding: 28px 20px;
  }
}
@media screen and (max-width: 480px) {
  .app-context.share .info-area {
    padding: 20px;
  }
}
@media screen and (min-width: 431px) and (max-width: 480px) {
  .app-context.share .info-area {
    padding: 20px;
  }
}

@media screen and (min-width: 391px) and (max-width: 430px) {
  .app-context.share .info-area {
    padding: 20px;
  }
}
@media screen and (min-width: 370px) and (max-width: 390px) {
  .app-context.share .info-area {
    padding: 15px 20px;
  }
}
.info-area {
  display: flex;
  background: #fff;
  padding: 28px 20px;
  position: relative;
  min-height: 740px;
  justify-content: center;

  .h3-wrapper {
    width: 100%;
    text-align: center;
    h3 {
      font-size: 30px;
      @media screen and (max-width: 768px) {
        font-size: 26px;
      }
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }
    h3.h3-title.flag {
      line-height: 1.4;
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
      &.c {
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
        @media screen and (max-width: 480px) {
          font-size: 15px;
        }
        @media screen and (max-width: 376px) {
          font-size: 13px;
        }
      }
      &.d {
        font-size: 33px;
        @media screen and (max-width: 768px) {
          font-size: 28px;
        }
        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
        @media screen and (max-width: 376px) {
          font-size: 12px;
        }
      }
    }
  }
   .area {
    &.type1 {
      .p-wrapper-flag {
        margin: 5px auto 10px auto;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: -0.6px;
          text-align: center;
          color: #363739;
          @media screen and (max-width: 768px) {
            font-size: 28px;
          }
          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
          @media screen and (max-width: 376px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .area5type {
    width: 100%;
  }

  .type9List {
    width: 90%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .p-wrapper {
      margin: 0 auto 20px auto;
      text-align: center;
      p {
        font-family: "AppleSDGothicNeoB00";
        font-size: 30px;
        line-height: 1.33;
        letter-spacing: -0.6px;
        color: #363739;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        margin: 5px auto;
        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
        @media screen and (max-width: 376px) {
          font-size: 13px;
        }
      }
    }
  }

  .type10List,
  .type11List {
    width: 100%;
    .p-wrapper {
      margin: 0 auto 20px auto;
      text-align: center;
      p {
        font-family: "AppleSDGothicNeoB00";
        font-size: 30px;
        line-height: 1.33;
        letter-spacing: -0.6px;
        color: #363739;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        margin: 5px auto;
        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
        @media screen and (max-width: 376px) {
          font-size: 13px;
        }
      }
    }
  }

  /* 중복정의가 많이 생기면서 img-wrapper 클래스를 공통 클래스로 사용 중 */
  .img-wrapper {
    img {
      width: 100%;
      height: auto;
    }

    &.type3 {
      .card-img:nth-child(1) {
        img {
          margin: 5% 0px 0px 7.5%;
        }
      }
    }

    &.type4 {
      margin-top: -10%;
    }
    &.type4.else {
      margin: 0;
      .p-wrapper {
        margin: 5px auto 10px auto;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: -0.6px;
          text-align: center;
          color: #363739;
          @media screen and (max-width: 768px) {
            font-size: 28px;
          }
          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
          @media screen and (max-width: 376px) {
            font-size: 12px;
          }
        }
      }
    }

    &.type5 {
      margin: 0 auto;
      display: flex;
      justify-content: center;

      p {
        font-family: "AppleSDGothicNeoB00";
        font-size: 30px;
        line-height: 1.33;
        letter-spacing: -0.6px;
        color: #363739;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
        @media screen and (max-width: 376px) {
          font-size: 13px;
        }

      }

      .card-img {
        margin: 0;

        .p-wrapper {
          width: 90%;
          position: absolute;
          text-align: center;
          top: 0;
          transform: translate(0, 26%);
          @media screen and (max-width: 768px) {
            width: calc(95% - 7px);
            transform: translate(0, 17%);
          }
          @media screen and (max-width: 480px) {
            width: calc(90% - 5px);
            transform: translate(0, 26%);
          }
          @media screen and (max-width: 376px) {
            width: calc(88% - 2px);
            transform: translate(0, 22%);
          }
          div {
            margin: 50px auto;
            @media screen and (max-width: 480px) {
              margin: 18px auto;
            }
            @media screen and (max-width: 376px) {
              width: 95%;
              margin: 15px auto;
            }
          }
        }

        &.type5-b {
          .p-wrapper-flag {
            margin: 5px auto 10px auto;
            p {
              font-family: "AppleSDGothicNeoB00";
              font-size: 30px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: -0.6px;
              text-align: center;
              color: #363739;
              @media screen and (max-width: 768px) {
                font-size: 28px;
              }
              @media screen and (max-width: 480px) {
                font-size: 14px;
              }
              @media screen and (max-width: 376px) {
                font-size: 12px;
              }
            }
          }

          .p-wrapper {
            width: calc(91% - 3px);
            top: 0;
            transform: translate(0, 45%);
            div {
              margin: 30px auto;
            }

            p {
              color: #ffffff;
            }

            @media screen and (max-width: 768px) {
              width: calc(95% - 3px);
              transform: translate(0, 37%);
            }

            @media screen and (max-width: 480px) {
              width: calc(90% - 4px);
              transform: translate(0, 23%);
              div {
                margin: 20px auto;
              }
            }
             @media screen and (max-width: 376px) {
              width: calc(90% - 4px);
              transform: translate(0, 15%);
              div {
                margin: 20px auto;
              }
            }
          }
        }

        &.type5-c {
          .p-wrapper-flag {
            margin: 0 auto 20px auto;
            p {
              font-family: "AppleSDGothicNeoB00";
              font-size: 30px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: -0.6px;
              text-align: center;
              color: #363739;
              margin: 5px auto;
              @media screen and (max-width: 768px) {
                font-size: 26px;
              }
              @media screen and (max-width: 480px) {
                font-size: 13px;
              }
              @media screen and (max-width: 376px) {
                font-size: 11px;
              }
            }
          }
          .p-wrapper {
            transform: translate(0, 23%);
            width: calc(95% - 11px);
            div {
              margin: 40px auto;
            }

            @media screen and (max-width: 768px) {
              width: calc(95% - 6px);
              transform: translate(0, 14%);
            }

            @media screen and (max-width: 480px) {
              width: calc( 90% - 2px );
              transform: translate(0, 8%);
              div {
                margin: 25px auto;
              }
              p {
                font-size: 13px;
              }
            }
            @media screen and (max-width: 376px) {
              width: calc( 90% - 7px );
              transform: translate(0, 14%);
              div {
               margin: 17px auto;
              }
              p {
                font-size: 12px;
              }
            }

          }
        }
        &.type5-d {
          .p-wrapper-flag {
            margin: 0 auto 20px auto;
            p {
              font-family: "AppleSDGothicNeoB00";
              font-size: 30px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: -0.6px;
              text-align: center;
              color: #363739;
              margin: 5px auto;
              @media screen and (max-width: 768px) {
                font-size: 26px;
              }
              @media screen and (max-width: 480px) {
                font-size: 14px;
              }
              @media screen and (max-width: 376px) {
                font-size: 12px;
              }
            }
          }
          .img-wrapper-flag {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
            .img-top{
              img {
                width: 100%;
                height: auto;
                transform: translateY(1.5%);
              }
            }
            .img-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          .p-wrapper {
            transform: translate(0, 35%);
            width: calc(95% - 11px);
            div {
              margin: 40px auto;
            }

            @media screen and (max-width: 768px) {
              width: calc(95% - 4px);
              transform: translate(0, 30%);
            }

            @media screen and (max-width: 480px) {
              width: 89%;
              transform: translate(0, 16%);
              p {
                font-size: 13px;
              }
              div {
                margin: 25px auto;
              }
            }
            @media screen and (max-width: 376px) {
              width: 87%;
              transform: translate(0, 18%);
              p {
                font-size: 11px;
              }
              div {
                margin: 20px auto;
              }
            }

          }
        }

        &.type5-special {
          .p-title {
            margin: 5% auto 10px auto;
            text-align: center;
            p {
              margin: 10px auto;
              @media screen and (max-width: 786px) {
                font-size:25px;
              }
              @media screen and (max-width: 480px) {
                font-size:16px;
              }
              @media screen and (max-width: 376px) {
                font-size:14px;
              }
            }
          }
          .p-wrapper {
            top: 30%;
            width: calc(38% - 11px);
            transform: translate(7%, 15%);
            left: 30%;
            padding: 5px;
            &.flagAdd {
              top: 23%;
            }
            @media screen and (max-width: 786px) {
              top: 32%;
              width: calc(38% - 14px);
              transform: translate(8%, 3%);
              left: 30%;
              &.flagAdd {
                top: 25%;
              }
            }
            @media screen and (max-width: 480px) {
              top: 30%;
              width: calc(38% - 14px);
              transform: translate(11%, 19%);
              left: 30%;
              &.flagAdd {
                top: 16.5%;
              }
            }
            @media screen and (max-width: 376px) {
              top: 30%;
              width: calc(38% - 15px);
              transform: translate(12%, -7%);
              left: 30%;
              &.flagAdd {
                top: 17%;
              }
            }

            .p-a {
              margin: 95px auto;
              @media screen and (max-width: 480px) {
                  margin: 35px 5px;
              }
              @media screen and (max-width: 376px) {
                  margin: 28px 3px;
              }
              p {
                font-size: 35px;

                @media screen and (max-width: 768px) {
                  font-size: 25px;
                }
                @media screen and (max-width: 480px) {
                  font-size: 14px;
                }
                @media screen and (max-width: 376px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    &.type6 {
      display: flex;
      @media screen and (max-width: 768px) {
        flex-flow: column;
        align-items: center;
      }

      .img-title {
        position: absolute;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        font-family: "AppleSdGothicNeoB00";
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 1.33;
        letter-spacing: -0.6px;
        margin: 20px auto;
        width: calc(50% - 50px);
        @media screen and (max-width: 768px) {
          width: calc(60% - 5px);
          margin: 21px auto;
        }
        @media screen and (max-width: 480px) {
          font-size: 20px;
          width: calc(90% - 4px);
        }
        @media screen and (max-width: 376px) {
          margin: 15px auto;
        }
      }

      .p-wrapper {
        margin: 10px auto;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: -0.6px;
          text-align: center;
          color: #363739;
          @media screen and (max-width: 768px) {
            font-size: 25px;
          }
          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
          @media screen and (max-width: 376px) {
            font-size: 13px;
          }
        }
      }

      .img-wrapper-left,
      .img-wrapper-right {

        width: 50%;
        margin: 0 15px;

        @media screen and (max-width: 768px) {
          width: auto;
          margin: 20px 15px;
        }
        @media screen and (max-width: 480px) {
          width: 100%;
        }

        .img-content {
          top: 19%;
          position: absolute;
          width: calc(50% - 50px);
          @media screen and (max-width: 768px) {
            width: calc(60% - 5px);
            &.left {
              top: 16.5%;
            }
            &.right {
              top: 63.5%;
            }

          }
          @media screen and (max-width: 480px) {
            width: calc(90% - 5px);
          }
          @media screen and (max-width: 376px) {
            width: calc(90% - 5px);
            &.left {
              top: 15.5%;
            }
            &.right {
              top: 60.5%;
            }
          }
          .p-wrapper {
            margin: 15px;
            background: #ffffff;
            border-radius: 5px;
            height: 60px;

            p {
              transform: translateY(30%);
              font-family: "AppleSDGothicNeoB00";
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: -0.4px;
              text-align: center;
              color: #292a2b;
            }
            @media screen and (max-width: 480px) {
              height: 42px;
              p {
                font-size: 14px;
              }
            }
            @media screen and (max-width: 376px) {
              height: 35px;
              p {
                line-height: 1.6;
              }
            }
          }

        }

      }
      &.b {
        flex-direction: column;
        align-items: center;
        .img-title {
          &.flag {
            width: calc(60% - 14px);
            line-height: 1.8;
            font-size: 35px;
            margin: 27px auto 27px auto;
            @media screen and (max-width: 768px) {
              width: calc(65% - 15px);
              line-height: 1.4;
            }
            @media screen and (max-width: 480px) {
              margin: 13px auto;
              font-size: 14px;
              width: calc(67% - 15px);
            }
          }
        }
        .img-wrapper-left,
        .img-wrapper-right {
          &.flag {
            width: 70%;
          }
        }
        .img-content {
          &.flag {
            width: calc(60% - 14px);
            top: 33%;
            @media screen and (max-width: 768px) {
              width: calc(65% - 15px);
              top: 32%;
            }
            @media screen and (max-width: 480px) {
              top: 20%;
            }
            @media screen and (max-width: 376px) {
              top: 18%;
            }

            .p-wrapper {
              height: 80px;
              p {
                font-size: 30px;
                line-height: 1.8;
              }
              @media screen and (max-width: 768px) {
                height: 60px;
                p {
                  line-height: 1.6;
                  font-size: 25px;
                }
              }
              @media screen and (max-width: 480px) {
                height: 26px;
                p {
                  font-size: 11px;
                  line-height: 1.6;
                }
              }
              @media screen and (max-width: 376px) {
                height: 21px;
                p {
                  font-size: 10px;
                  line-height: 1.4;
                }
              }
            }
          }
        }
      }
      &.a {
        .img-content {
          .p-wrapper {
            height: 45px;
            p {
              line-height: 1.5;
            }
            @media screen and (max-width: 480px) {
              height: 31px;
            }
            @media screen and (max-width: 376px) {
              height: 25px;
              p {
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
    &.type7 {
      img {
        width: auto;
      }

      .content-title {
        margin: 20px auto;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.6px;
          text-align: center;
          color: #363739;
        }
        @media screen and (max-width: 768px) {
          p {
            font-size: 22px;
          }
        }
        @media screen and (max-width: 480px) {
          p {
            font-size: 15px;
          }
        }
        @media screen and (max-width: 376px) {
          p {
            font-size: 13px;
          }
        }
      }

      &.b {
        @media screen and (max-width: 480px) {
          .content-title {
            width: calc(100% - 35px);
          }
          p {
            margin: 10px auto;
            font-size: 18px;
          }
        }
        @media screen and (max-width: 376px) {
          .content-title {
            width: calc(100% - 42px);
          }
          p {
            margin: 10px auto;
            font-size: 16px;
          }
        }
      }

      .content-img {
        display: grid;
        margin: 20px auto 35px auto;
        place-content: center;
        img {
          max-width: 600px;
          height: auto;
          padding: 20px;
          margin: auto;

          @media screen and (max-width: 768px) {
            max-width: 460px;
          }

          @media screen and (max-width: 480px) {
            max-width: 300px;
            &:nth-child(2) {
              max-width: 151px;
            }
            padding: 10px;
          }
        }
      }
    }
    &.type8 {
      .p-wrapper-flag {
        margin: 10px auto;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.6px;
          text-align: center;
          color: #363739;
          @media screen and (max-width: 480px) {
            font-size: 16px;
          }
          @media screen and (max-width: 376px) {
            font-size: 14px;
          }
        }
      }
      .p-wrapper {
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.6px;
          text-align: center;
          color: #363739;
          @media screen and (max-width: 480px) {
            font-size: 16px;
          }
          @media screen and (max-width: 376px) {
            font-size: 14px;
          }
        }
      }
      &.b {
        .p-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(65%, 60%);
          p {
            font-family: "AppleSDGothicNeoB00";
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.6px;
            text-align: center;
            color: #363739;
          }

          @media screen and (max-width: 768px) {
            transform: translate(38%, 40%);
          }

          @media screen and (max-width: 480px) {
            transform: translate(33.5%, 40%);
            p {
              font-size: 16px;
            }
          }
          @media screen and (max-width: 376px) {
            transform: translate(34.5%, 40%);
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
    &.type9 {

      .content-title {
        margin: auto;
        text-align: center;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: -0.6px;
          color: #fd8b11;
        }

        @media screen and (max-width: 768px) {
          p {
            font-size: 26px;
          }
        }
        @media screen and (max-width: 480px) {
          p {
            font-size: 14px;
          }
        }
      }

      .content-body {
        margin: 20px auto;
        width: 100%;
        height: 90px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #ebf2f1;

        &.active {
          border: 3px solid #FD8B11;
          border-radius: 5px;
        }

        @media screen and (max-width: 480px) {
          height: 70px;
          margin: 15px auto;
        }

        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 22px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.44px;
        }

        @media screen and (max-width: 480px) {
          p {
            font-size: 14px;
          }
        }

        .body-top {
          color: #fd8b11;
          background-color: #FFF3E6;
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .body-bottom {
          background-color: #fff;
          color: #292a2b;
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    &.type10 {
      img {
        width: 26px;
        height: 26px;
        margin: 0 5px;
      }
      padding: 5px 35px;

      @media screen and (max-width: 768px) {
        padding: 5px 15px;
      }
      .p-wrapper {
        p {
          @media screen and (max-width: 480px) {
            font-size: 12px;
          }
          @media screen and (max-width: 376px) {
            font-size: 10px;
          }
        }
      }

      .content {

        .content-title {
          display: flex;
          margin: 20px auto;

          p {
            margin: auto 5px;
            font-family: "AppleSDGothicNeoB00";
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: -0.6px;
            text-align: left;
            color: #fd8b11;
          }
          @media screen and (max-width: 768px) {
            p {
              font-size: 26px;
            }
          }
          @media screen and (max-width: 480px) {
            &.a {
              margin: 0 auto 20px -15px;
            }
            &.b {
              margin: 20px auto 20px -15px;
            }
            p {
              font-size: 14px;
            }
          }
        }
        .content-body {
          background-color: #eef1f3;
          border-radius: 5px;
          opacity: 0.8;
          width: 800px;
          height: 380px;
          margin: 20px auto;

          p {
            padding: 30px;
            font-family: "AppleSDGothicNeoB00";
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: -0.44px;
            text-align: left;
            color: #363739;
          }
          @media screen and (max-width: 768px) {
            width: 616px;
            height: 380px;
          }
          @media screen and (max-width: 480px) {
            margin: 0 0 0 -10px;
            p {
              padding: 20px;
              font-size: 14px;
            }
            width: 314px;
            height: 260px;
          }
           @media screen and (max-width: 376px) {
            margin: 0 0 0 -10px;
            p {
              padding: 17px;
              font-size: 14px;
            }
            width: 275px;
            height: 230px;
          }
        }
      }
    }

    &.type11 {
      .content-title {
        background-color: #e64f60;
        border-radius: 5px;
        width: 95%;
        height: 70px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
           font-family: "AppleSDGothicNeoB00";
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.6px;
            text-align: center;
            color: #ffffff;
        }

        @media screen and (max-width: 480px) {
          height: 41px;
          border-radius: 2px;
          p {
            font-size: 16px;
          }
        }

      }
      .content-body {
        width: 95%;
        height: 100%;
        margin: 0 auto;
        padding: 20px;
        background-color: rgba(230, 79, 96, 0.1);

        .body-list {
          background-color: #ffffff;
          border-radius: 5px;
          width: 95%;
          margin: 10px auto;
          padding: 10px 30px;
          min-height: 3.7vw;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-family: "AppleSDGothicNeoB00";
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: -0.44px;
            text-align: center;
            color: #292a2b;
          }

          @media screen and (max-width: 480px) {
            border-radius: 2px;
            padding: 10px 15px;
            p {
              font-size: 14px;
            }
          }

        }
      }
    }
  }
}
</style>
