<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <directive :directiveTxt="directiveTxt" class="directive low"></directive>
      <swiper-card
        :swiperList="swiperList"
        :swiperOrder="apiStatus.currentOrder"
        @into-list="intoList"
        v-if="swiperList.length > 0"
        ref="swiperCard"
      ></swiper-card>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <information-popup
      v-if="checkInformationPopup"
      :informationPopupSet="informationPopupSet"
      @close-pop="closedPop"
      @start-pop="startPop"
      @share-pop-call="sharePopCall"
    />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import SwiperCard from '@/components/SwiperCard.vue';
import Notification from '@/components/Notification.vue';
import Directive from '@/components/Directive.vue';
import InformationPopup from '@/components/modal/InformationPopup';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      swiperList: [],
      swiperOrder: null,
      directiveTxt: {
        point: '삶의 소중함과 의미',
        normal: '를 찾아보세요.',
        small: '(매주 5일, 총 8주 동안 진행해주세요)',
      },
      notificationInfo: {
        title: '삶의 의미 탐색',
        btnSet: [
          {
            img: 'icon_information.svg',
            name: '탐색안내',
            routerUrl: 'searchInfo',
            isActionBtn: false,
          },
          {
            img: 'search.png',
            name: '탐색목록',
            routerUrl: 'searchList',
            isActionBtn: false,
          },
        ],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: true,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      informationPopupSet: {},
      popupSet: {},
      apiStatus: {
        realDay: null,
        day: null,
        lifeDay: null,
        trainingDay: null,
        isTotalCompleted: null,
        isLifeCompleted: null,
        isTrainingCompleted: null,
        currentOrder: null,
      },
      view: {
        isProceedingLife: null,
        fetchList: [],
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    window.lifeOut = this.lifeOut;
    this.prepareLifeList(false);
    this.prepareShareList();
    /**
     * (1) 삶의 의미 리스트 패치
     * (2) 현재 삶의의미 순번 캐치
     * */
  },
  mounted() {
    /**
    this.SET_INFORMATION_POPUP(true);
    this.informationPopupSet.popType = 1;
    this.informationPopupSet.commentType = "life";
    this.informationPopupSet.isApiCall = true;
    this.informationPopupSet.commentTime = "end";
    this.informationPopupSet.commentDay = 6;
    this.informationPopupSet.commentTrainingTime = null;
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_INFORMATION_POPUP']),
    ...mapActions('util', ['FETCH_ENTRY']),
    ...mapActions('life', ['FETCH_LIFE_LIST_FULL']),
    sharePopCall(item) {
      let param;
      if (item.day === 6) {
        console.log('팝에서 온 아이템 :');
        console.log('userId :', localStorage.getItem('id'));
        param = `${window.location.href.replace('choiceMeaning', 'share/lifelist')}?id=${
          item.id
        }&userId=${localStorage.getItem('id')}`;
        console.log('param : ', param);
      } else if (item.day === 39) {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('choiceMeaning', 'share/lifelist')}?id=${
          item.id
        }&userId=${localStorage.getItem('id')}&day=${item.day}`;
        console.log('param : ', param);
      } else if (item.day === 10) {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('choiceMeaning', 'share/life')}?id=${
          item.id
        }&day=${item.day}&title=${encodeURI(item.title)}&userId=${localStorage.getItem(
          'id',
        )}`;
      } else {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('choiceMeaning', 'share/life')}?id=${
          item.id
        }&day=${item.day}&title=${encodeURI(item.title)}`;
      }
      console.log('param :', param);
      this.SET_POPUP(true);
      this.popupSet.popType = 'share';
      this.popupSet.paramObj = item;
      this.popupSet.param = param;
    },
    startPop(day) {
      console.log('삶의의미 진입호출');
      const url = `samkim://lifeActivity?day=${day}`;
      document.location.href = url;
    },
    closedPop(day) {
      this.prepareLifeList(false);
      /**
      if (day < this.apiStatus.realDay) {
        this.reloadEntryPop();
      }
       */
    },
    async reloadEntryPop() {
      try {
        /** 삶의의미탐색 끝난 일자가 리얼데이와 동일하면 start 초기 팝업 호출안합니다.  */
        await this.FETCH_ENTRY().then((data) => {
          console.log('정보 :', data);
          // 초기화
          this.apiStatus.realDay = data.response.realDay;
          this.apiStatus.day = data.response.day;
          this.apiStatus.lifeDay = data.response.lifeDay;
          this.apiStatus.trainingDay = data.response.trainingDay;
          this.apiStatus.isTotalCompleted = data.response.totalCompleted;
          this.apiStatus.isTrainingCompleted = data.response.trainingCompleted;
          this.apiStatus.isLifeCompleted = data.response.lifeCompleted;
          // 팝업호출
          this.SET_INFORMATION_POPUP(true);
          this.informationPopupSet.commentType = 'total';
          this.informationPopupSet.commentTime = 'start';
          this.informationPopupSet.commentDay = this.apiStatus.day;
          this.informationPopupSet.commentTrainingTime = null;
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async lifeOut(day, isDone) {
      console.log('day :', day);
      console.log('isDone :', isDone);
      // 해당 day로 삶의의미 종료팝업 호출한뒤
      // emit 설계함 , 공유하기 대응, 나가기 대응 , 팝인팝
      if (isDone) {
        this.SET_INFORMATION_POPUP(true);
        this.informationPopupSet.commentType = 'life';
        this.informationPopupSet.commentTime = 'end';
        this.informationPopupSet.commentDay = day;
        this.informationPopupSet.isStartApiCall = false;
        this.informationPopupSet.commentTrainingTime = null;
        this.informationPopupSet.isApiCall = true;
        this.informationPopupSet.popType = 1;
      } else {
        this.prepareLifeList(false);
      }
    },
    intoList(day, isDone) {
      console.log('진행을 마무리했나요?', isDone);
      console.log('day :', day);
      console.log('apiStatus.realDay :', this.apiStatus.realDay);
      console.log('apiStatus.day :', this.apiStatus.day);
      console.log('apiStatus.lifeDay :', this.apiStatus.lifeDay);
      console.log('apiStatus.trainingDay :', this.apiStatus.trainingDay);
      console.log('apiStatus.isTotalCompleted :', this.apiStatus.isTotalCompleted);
      console.log('apiStatus.isTrainingCompleted :', this.apiStatus.isTrainingCompleted);
      console.log('apiStatus.isLifeCompleted :', this.apiStatus.isLifeCompleted);
      if (day < this.apiStatus.lifeDay) {
        console.log('과거 금지 진입');
        // 과거금지
        this.SET_POPUP(true);
        this.popupSet.content = '이미 완료하였습니다.';
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (day > this.apiStatus.lifeDay) {
        console.log('미래 금지 진입');
        // 미래금지
        this.SET_POPUP(true);
        if (this.apiStatus.isLifeCompleted) {
          this.popupSet.content = '예정된 탐색훈련을 모두 마쳤습니다.';
        } else {
          this.popupSet.content = `${this.apiStatus.lifeDay}일차 탐색을 먼저 진행해주세요`;
        }
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (this.apiStatus.isLifeCompleted) {
        this.SET_POPUP(true);
        this.popupSet.content = '이미 완료하였습니다.';
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else {
        this.checkComplex(day);
      }
      /**
      if (day < this.apiStatus.lifeDay) {
        console.log("과거 금지 진입");
        // 과거금지
        this.SET_POPUP(true);
        this.popupSet.content = "이미 완료하였습니다.";
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (day > this.apiStatus.lifeDay) {
        console.log("미래 금지 진입");
        // 미래금지
        this.SET_POPUP(true);
        if (this.apiStatus.isLifeCompleted) {
          this.popupSet.content = `${
            this.apiStatus.lifeDay + 1
          }일차 탐색을 먼저 진행해주세요`;
        } else {
          this.popupSet.content = `${this.apiStatus.lifeDay}일차 탐색을 먼저 진행해주세요`;
        }
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (this.apiStatus.realDay === this.apiStatus.day) {
        console.log("실제일과 현재 일치");
        if (day === this.apiStatus.lifeDay) {
          if (!this.apiStatus.isTrainingCompleted) {
            this.SET_POPUP(true);
            this.popupSet.content = `${this.apiStatus.trainingDay}일차 훈련을 먼저 진행해주세요`;
            this.popupSet.popImg = "warning.svg";
            this.popupSet.cancelBtnText = "확인";
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          } else if (this.apiStatus.isLifeCompleted) {
            this.SET_POPUP(true);
            this.popupSet.content = "이미 완료하였습니다.";
            this.popupSet.popImg = "warning.svg";
            this.popupSet.cancelBtnText = "확인";
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          } else {
            // 연결
            this.checkEntry(true);
          }
        }
      } else if (this.apiStatus.trainingDay === this.apiStatus.lifeDay) {
        console.log("훈련일과 동일할 경우 진입");
        this.SET_POPUP(true);
        this.popupSet.content = `${this.apiStatus.trainingDay}일차 훈련을 먼저 진행해주세요`;
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else {
        this.checkEntry(true);
      }
       */
    },
    async prepareShareList() {
      try {
        const theory = [];
        await this.FETCH_LIFE_LIST_FULL().then((data) => {
          for (let i = 0; i < data.response.list.length; i++) {
            theory.push(data.response.list[i]);
          }
        });
        this.view.fetchList = theory;
      } catch (error) {
        console.log('error :', error);
      }
    },
    async getDayIsCompleted(day) {
      await this.FETCH_LIFE_LIST_FULL().then((data) => {
        for (let i = 0; i < data.response.list.length; i++) {
          if (data.response.list[i].day === day) {
            this.view.isProceedingLife = data.response.list[i].progress > 0;
          }
        }
      });
    },
    async checkComplex(day) {
      await this.getDayIsCompleted(day);
      await this.checkEntry(true, day);
    },
    async prepareLifeList(isSpread) {
      await this.checkEntry(isSpread);
      await this.fetchLifeList();
    },
    async fetchLifeList() {
      try {
        const sampleArr = [];
        this.swiperList = sampleArr;
        await this.FETCH_LIFE_LIST_FULL().then((data) => {
          console.log('data :', data);
          for (let i = 0; i < data.response.list.length; i++) {
            if (data.response.list[i].day === this.apiStatus.lifeDay) {
              this.apiStatus.currentOrder = i;
            }
            sampleArr.push(data.response.list[i]);
          }
          this.swiperList = sampleArr;
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async checkEntry(isSpread, day) {
      try {
        await this.FETCH_ENTRY().then((data) => {
          console.log('정보 :', data);
          this.apiStatus.realDay = data.response.realDay;
          this.apiStatus.day = data.response.day;
          this.apiStatus.lifeDay = data.response.lifeDay;
          this.apiStatus.trainingDay = data.response.trainingDay;
          this.apiStatus.isTotalCompleted = data.response.totalCompleted;
          this.apiStatus.isTrainingCompleted = data.response.trainingCompleted;
          this.apiStatus.isLifeCompleted = data.response.lifeCompleted;
          if (isSpread && !this.view.isProceedingLife) {
            this.SET_INFORMATION_POPUP(true);
            this.informationPopupSet.commentType = 'life';
            this.informationPopupSet.commentTime = 'start';
            this.informationPopupSet.isStartApiCall = true;
            this.informationPopupSet.commentDay = data.response.lifeDay;
            this.informationPopupSet.commentTrainingTime = null;
            this.informationPopupSet.popType = 1;
            /**
            if (data.response.realDay > data.response.day) {
              if (data.response.trainingDay > data.response.lifeDay) {
                this.SET_INFORMATION_POPUP(true);
                this.informationPopupSet.commentType = "life";
                this.informationPopupSet.commentTime = "start";
                this.informationPopupSet.isStartApiCall = true;
                this.informationPopupSet.commentDay = data.response.lifeDay;
                this.informationPopupSet.commentTrainingTime = null;
                this.informationPopupSet.popType = 1;
              }
            } else if (
              this.apiStatus.isTrainingCompleted &&
              !this.apiStatus.isLifeCompleted
            ) {
              this.SET_INFORMATION_POPUP(true);
              this.informationPopupSet.commentType = "life";
              this.informationPopupSet.commentTime = "start";
              this.informationPopupSet.isStartApiCall = true;
              this.informationPopupSet.commentDay = data.response.lifeDay;
              this.informationPopupSet.commentTrainingTime = null;
              this.informationPopupSet.popType = 1;
            }
             */
          } else if (isSpread && this.view.isProceedingLife) {
            console.log('삶의의미 진입호출 - 조건절에서');
            const url = `samkim://lifeActivity?day=${day}`;
            document.location.href = url;
          }
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    handleGoback() {
      console.log('리듀서 - 카드');
      console.log('플래그 상태 - 카드:', this.checkInformationPopup);
      if (this.checkInformationPopup) {
        this.SET_INFORMATION_POPUP(false);
      } else {
        this.$router.push('/');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkInformationPopup']),
  },
  beforeDestroy() {
    if (this.checkInformationPopup) {
      this.SET_INFORMATION_POPUP(false);
    }
    if (this.checkPopup) {
      this.SET_POPUP(false);
    }
  },
  components: {
    InformationPopup,
    SideMenu,
    Notification,
    Directive,
    DefaultPopup,
    SwiperCard,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 56px 0;
    }
    .directive.row {
      margin: 0 0 25px 0;
    }
  }
}
</style>
